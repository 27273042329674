import { Link } from "react-router-dom";
import "./footer.scss";
import { delay, motion } from "framer-motion";

interface footerProps {
    overline: string;
    copy: string;
    btn: string;
    link: string;
    top?: boolean;
}

const variantX = (x: number) => ({
    initial: {
        y: 100,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            effect: "ease",
            delay: x * 0.2,
        },
    },
});

const Footer: React.FC<footerProps> = ({
    overline,
    copy,
    btn,
    link,
    top = true,
}) => {
    return (
        <>
            <div className="footer-wrapper" id='footer'>
                {top && (
                    <div className="top-section">
                        <div className="copy-wrapper">
                            <motion.p
                                initial="initial"
                                whileInView="animate"
                                variants={variantX(0)}
                                className="overline"
                            >
                                {overline}
                            </motion.p>
                            <motion.h2
                                initial="initial"
                                whileInView="animate"
                                variants={variantX(0)}
                                className="b"
                            >
                                {copy}
                            </motion.h2>
                            <motion.div
                                initial="initial"
                                whileInView="animate"
                                variants={variantX(0)}
                            >
                                <Link to={`/${link}`} className="btn-primary">
                                    <p>{btn}</p>
                                </Link>
                            </motion.div>
                        </div>
                    </div>
                )}
                <div className="green-wrapper">
                    <div className="agents-section">
                        <div className="left">
                            <h2>
                                Wigmore Yard,
                                <br />
                                Wigmore Street, W1
                                <br />
                                -<br />
                                12,822 sq ft
                                <br />
                                Available April, 2025
                                <br />
                            </h2>
                            <p className="overline">DOWNLOAD</p>
                            <div className="btn-wrapper">
                                <a
                                    href="../dl/brochure.pdf"
                                    target="_blank"
                                    className="btn-pill dl"
                                >
                                    <p>BROCHURE</p>
                                    <img
                                        src="../images/download.svg"
                                        alt="download"
                                    />
                                </a>
                                <a
                                    href="../dl/floorplans.pdf"
                                    target="_blank"
                                    className="btn-pill dl"
                                >
                                    <p>FLOORPLANS</p>
                                    <img
                                        src="../images/download.svg"
                                        alt="download"
                                    />
                                </a>
                            </div>
                            <img
                                src="../images/logo.svg"
                                className="logo"
                                alt="logo"
                            />
                        </div>
                        <div className="right">
                            <div className="agents-wrapper">
                                <div className="agents">
                                    <div className="agent">
                                        <img src="../images/cbre.svg" />
                                    </div>
                                    <div className="agent">
                                        <p>Alex Kerr</p>
                                        <p>07867 185 924</p>
                                        <a href="mailto:alex.kerr@cbre.com">
                                            <p>alex.kerr@cbre.com</p>
                                        </a>
                                    </div>
                                    {/* <div className="agent">
                                        <p>Phoebe Thurlbeck</p>
                                        <p>07443 267 817</p>
                                        <a href="mailto:phoebe.thurlbeck@cbre.com">
                                            <p>phoebe.thurlbeck@cbre.com</p>
                                        </a>
                                    </div> */}
                                    <div className="agent">
                                        <p>Adam Cosgrove</p>
                                        <p>07500 872 851</p>
                                        <a href="mailto:adam.cosgrove@cbre.com">
                                            <p>adam.cosgrove@cbre.com</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="agents">
                                    <div className="agent">
                                        <img src="../images/coH.svg" />
                                    </div>
                                    <div className="agent">
                                        <p>Richard Lockhart</p>
                                        <p>07780 661 096</p>
                                        <a href="mailto:richard@coh.eu">
                                            <p>richard@coh.eu</p>
                                        </a>
                                    </div>
                                    <div className="agent">
                                        <p>Nick Sinclair</p>
                                        <p>07970 551 730</p>
                                        <a href="mailto:nick@coh.eu">
                                            <p>nick@coh.eu</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="borders-wraps">
                                <p>A development by</p>
                                <div className="img-wrapper">
                                    <img src="../images/abrdn.svg" alt="logo" />
                                </div>
                            </div>
                            <div className="misrep">
                                <p>
                                    Misrepresentation Act 1967. CBRE and
                                    Crossland Otter Hunt and their clients give
                                    notice that: CBRE and Crossland Otter Hunt
                                    for themselves and the vendor of this
                                    property give notice that these particulars
                                    do not form, or form part of, any offer or
                                    contract. They are intended to give fair
                                    description of the property and whilst every
                                    effort has been made to ensure their
                                    accuracy this cannot be guaranteed. All CGIs
                                    are for indicative purposes only. Any
                                    intending purchaser must therefore satisfy
                                    themselves by inspection or otherwise.
                                    Neither CBRE, Crossland Otter Hunt nor any
                                    of its employees has any authority to make
                                    or give any further representations or
                                    warranty whatsoever in relation to this
                                    property. All prices and rents quoted are
                                    net of VAT.
                                    <a
                                        style={{ color: "#f2ebd8" }}
                                        href="https://sirendesign.co.uk/"
                                    >
                                        {" "}
                                        sirendesign.co.uk{" "}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-container">
                        <div className="bottom">
                            <div className="copyright">
                                <p>© WIGMORE YARD 2024</p>
                            </div>
                            <div className="copyright">
                                <a>
                                    <p>TERMS</p>
                                </a>
                                <a>
                                    <p>PRIVACY</p>
                                </a>
                                <a>
                                    <p>COOKIES</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="siren">
                        <a href="https://sirendesign.co.uk/">
                            <p>Designed and Developed by Siren</p>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
